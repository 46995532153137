<template>
  <!-- 申请发票 -->
  <div class="applyInvoice">
    <!-- 面包屑 -->
    <a-breadcrumb separator=">">
      <a-breadcrumb-item
        ><router-link to="/personalCenter/MyOrder"
          >我的订单</router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item style="color: #15b7dd">换开发票</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="content_box">
      <!-- 请选择发票信息 -->
      <div class="box3">
        <div class="box_title">请选择发票信息</div>
        <div class="invoiceList">
          <div
            class="invoiceItem"
            :class="{ invoiceActive: invoiceIndex == index }"
            v-for="(item, index) in invoiceData"
            :key="index"
            @click="changeInvoice(index)"
          >
            <div class="message">
              <!-- 1.企业 2.机关执业单位 3.个人/非企业单位 4.其他 -->
              <img
                v-if="item.headerType == 1"
                src="@/assets/image/order/business.png"
                alt=""
              />
              <img
                v-if="item.headerType == 3"
                src="@/assets/image/order/personal.png"
                alt=""
              />
              <p class="headerType">
                {{ item.headerType == 1 ? "企业" : "个人" }}
              </p>
              <p class="name">{{ item.invoiceHeader }}</p>
            </div>
            <p class="code" v-if="item.headerType == 1">
              {{ item.dutyParagraph }}
            </p>
            <p class="email">{{ item.email }}</p>
            <div class="box_left_top">
              <div class="writeBox"></div>
            </div>
            <div class="box_left_bottom">
              <div class="writeBox"></div>
            </div>
            <div class="box_right_top">
              <div class="writeBox"></div>
            </div>
            <div class="box_right_bottom">
              <div class="writeBox"></div>
            </div>
          </div>
          <div
            class="invoiceAdd"
            :class="{
              invoiceAddT: invoiceData.length >= 3,
              invoiceAddL: invoiceData.length % 3 == 0,
            }"
            @click="(invoiceType = 1), (isAddInvoiceModal = true)"
          >
            <img src="@/assets/image/order/invoiceAdd.png" alt="" />
            <p>添加发票抬头</p>
          </div>
        </div>
      </div>
      <div class="box_button" >
        <div class="all-btn-blue" @click="goBack()">取消</div>
        <div class="all-btn-small" @click="onSave()">提交申请</div>
      </div>

      <!-- 添加发票/换开发票 -->
      <invoiceModal
        :invoiceType="invoiceType"
        :isAddInvoiceModal="isAddInvoiceModal"
        :invoiceRecordId="invoiceRecordId"
        @onCancelAdd="onCancelAdd"
        @onConfirmAdd="onConfirmAdd"
      ></invoiceModal>
    </div>
  </div>
</template>

<script>
import invoiceModal from "@/components/model/invoiceModal.vue"; // 添加发票抬头
export default {
  // 可用组件的哈希表
  components: {
    invoiceModal, // 添加发票抬头
  },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      orderId: null, //订单id
      invoiceData: [], //发票抬头列表
      invoiceIndex: -1, //发票选中index
      isLoading: false, //提交中

      invoiceRecordId: -1, //发票id
      invoiceType: 1, //1添加发票 2换开发票
      isAddInvoiceModal: false, //发票抬头弹框
      // 发票列表
      invoiceList: [],

    };
  },
  // 事件处理器
  methods: {
    // 选择发票信息
    changeInvoice(index) {
      this.invoiceIndex = index;
    },
    // 确定申请发票?
    onSave() {
      if (this.invoiceIndex == -1) {
        this.$message.info("请选择换开的发票抬头");
        return;
      }
      if(this.isLoading) return
      this.isLoading = true;

      let obj = this.invoiceData[this.invoiceIndex]

      obj.invoiceRecordIds = Array.of(Number(this.invoiceRecordId));
      obj.dutyParagraph = obj.dutyParagraph.trim();
       //trim()方法是用来删除字符串两端的空白字符并返回，trim方法并不影响原来的字符串本身，它返回的是一个新的字符串。缺陷：只能去除字符串两端的空格，不能去除中间的空格

      let params = {
        bankNo: obj.bankAccount, // 银行账号
        companyAddress: obj.companyAddress,	// 公司地址
        companyPhone: obj.companyPhone, // 公司电话
        email: obj.email, //	邮箱
        invoiceRecordIds: obj.invoiceRecordIds, // 发票记录id
        openBank: obj.bank, // 开户行
        taxNo: obj.dutyParagraph, // 税号
        title: obj.invoiceHeader, // 抬头
        type: obj.headerType,
        userInvoiceId: obj.userInvoiceId,
      }

      this.$ajax({
        url: "/hxclass-pc/invoice/change",
        method: "post",
        params: params,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success(
            "发票正在开具中，如有疑问，请联系工作人员，联系电话：400-800-9002转3。"
          );
          this.$router.go(-1)
        } else {
          this.isLoading = false;
          this.$message.error(res.message);
        }
      });
    },
    // 添加发票弹框 - 取消
    onCancelAdd() {
      this.isAddInvoiceModal = false;
    },
    // 添加发票弹框 - 确定
    onConfirmAdd() {
      this.isAddInvoiceModal = false;
      // 发票抬头列表
      this.getInvoiceHeadList();
    },
    // 取消
    goBack() {
      // 返回上一页
      this.$router.go(-1)
    },
    // 获取发票抬头列表
    getInvoiceHeadList() {
      this.$ajax({
        url: "/hxclass-pc/invoice/header/list",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.invoiceData = res.data;
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.invoiceRecordId = this.$AES.decode_data(this.$route.query.invoiceRecordId);
    // 获取发票抬头列表
    this.getInvoiceHeadList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.applyInvoice {
  width: 1400px;
  margin: 0 auto;
  padding-top: 92px;
  .content_box {
    margin-top: 22px;
    margin-bottom: 40px;
    padding: 40px 40px 60px 40px;
    background: #fff;
    border-radius: 5px;
    .box {
      .box_title {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 500;
        color: #15b7dd;
        &::before {
          content: "";
          display: inline-block;
          width: 4px;
          height: 19px;
          margin-right: 8px;
          background: #15b7dd;
        }
      }
    }
    // 请选择发票信息
    .box3 {
      .box_title {
        font-size: 18px;
        font-family: PingFang HK-Medium, PingFang HK;
        font-weight: 500;
        color: #333333;
        line-height: 27px;
      }
      .invoiceList {
        margin-top: 25px;
        display: flex;
        flex-wrap: wrap;
        .invoiceItem:nth-child(3n + 1) {
          margin: 0;
        }
        .invoiceItem:nth-child(n + 4) {
          margin-top: 18px;
        }
        .invoiceAdd {
          cursor: pointer;
          width: 32%;
          margin-left: 2%;
          padding-top: 48px;
          padding-bottom: 41px;
          text-align: center;
          border: 2px dashed #c4c4c4;
          img {
            width: 39px;
            height: 39px;
          }
          p {
            font-size: 18px;
            font-family: PingFang HK-Regular, PingFang HK;
            font-weight: 400;
            color: #666666;
            line-height: 27px;
            margin-top: 7px;
          }
        }
        .invoiceAddT {
          margin-top: 18px;
        }
        .invoiceAddL {
          margin-left: 0;
        }
        .invoiceItem {
          cursor: pointer;
          width: 32%;
          margin-left: 2%;
          padding: 12px 20px;
          border-top: 2px dotted #c4c4c4;
          border-bottom: 2px dotted #c4c4c4;
          position: relative;
          .box_left_top,
          .box_left_bottom,
          .box_right_top,
          .box_right_bottom {
            width: 25px;
            height: 25px;
            background: #c4c4c4;
            .writeBox {
              width: 21px;
              height: 21px;
              background: #ffffff;
            }
          }
          .box_left_top {
            position: absolute;
            left: -1px;
            top: -3px;
            .writeBox {
              position: relative;
              left: 4px;
              top: 4px;
            }
          }
          .box_left_bottom {
            position: absolute;
            left: -1px;
            bottom: -3px;
            .writeBox {
              position: relative;
              left: 4px;
            }
          }
          .box_right_top {
            position: absolute;
            right: -1px;
            top: -3px;
            .writeBox {
              position: relative;
              top: 4px;
            }
          }
          .box_right_bottom {
            position: absolute;
            right: -1px;
            bottom: -3px;
          }
          .email {
            margin-top: 16px;
            font-size: 16px;
            font-family: PingFang HK-Regular, PingFang HK;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
          }
          .code {
            margin-top: 16px;
            font-size: 16px;
            font-family: PingFang HK-Regular, PingFang HK;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
          }
          .message {
            display: flex;
            align-items: center;
            padding-bottom: 17px;
            border-bottom: 2px dashed #ebebeb;
            img {
              width: 20px;
              height: 20px;
              margin-right: 4px;
            }
            .headerType {
              font-size: 18px;
              font-family: PingFang HK-Regular, PingFang HK;
              font-weight: 400;
              color: #666666;
              line-height: 27px;
              margin-right: 18px;
            }
            .name {
              font-size: 18px;
              font-family: PingFang HK-Medium, PingFang HK;
              font-weight: bold;
              color: #333333;
              line-height: 27px;
            }
          }
        }
        .invoiceActive {
          border-top: 2px dotted #15b7dd;
          border-bottom: 2px dotted #15b7dd;
          .box_left_top,
          .box_left_bottom,
          .box_right_top,
          .box_right_bottom {
            background: #15b7dd;
          }
        }
      }
    }
    .box_button {
      display: flex;
      margin-top: 80px;
      .all-btn-blue {
        font-size: 16px;
        margin-right: 54px;
      }
      .all-btn-small {
        font-size: 16px;
      }
    }
  }
}
// 小屏分辨率内容区呈现1200px布局
@media screen and (max-width: 16000px) {
  .applyInvoice {
    width: 1200px;
    .content_box {
      .box .box_title {
        font-size: 22px;
      }
      .box2 .box_con {
        .con_info {
          font-size: 16px;
        }
        .con_type .type_item .item_check .item_txt .txt_title {
          font-size: 18px;
        }
      }
      .box3 .invoiceList .invoiceItem {
        .code {
          font-size: 14px;
        }
        .email {
          font-size: 14px;
        }
        .message {
          .headerType {
            font-size: 16px;
          }
          .name {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
